<!-- This has been migrated to ejs(SSR) and this component never gets opened as a page -->
<template>
  <div>
    <div class="row">
      <div :class="propOpenedInModal ? 'col-lg-12' : 'col-lg-8'">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog blog-detail">
          <div class="iq-card-body">
            <div v-if="blogDetail.blog_video" class="blog_videos">
              <iframe :src="blogDetail.blog_video" style="width: 100%;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div v-else>
              <div class="image-block" v-if="blogDetail.blog_img">
                <img :src="blogDetail.blog_img" class="img-fluid rounded w-100">
              </div>
              <div class="image-block" style="height: 460px;" v-else>
                <div style="background-color: #EEEEEE; width: 100%; height: 100%; border-radius: 5px; display: flex; text-align: center;">
                  <span style="margin: auto; font-size: 110px;">{{ blogDetail && blogDetail.blog_title ? blogDetail.blog_title.slice(0,1) : "" }}</span>
                </div>
              </div>
            </div>
            <div class="blog-description mt-2">
              <div class="row">
                <h4 class="pr-3 mb-1 col-12 border-bottom">{{ blogDetail.blog_title }} <br>
                  <span class="blog_date">{{ blogDetail.created_on|dateFormatMMMMDDYYYY }}</span>
                  <b-button class="font-size-20" variant="mr-1 mb-1" size="sm" v-if="userData && (userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111')" @click="blogEdit(blogDetail)">
                    <i class="ri-ball-pen-fill m-0"></i>
                  </b-button>
                </h4>
                <hr class="py-2"/>
              </div>

              <p class="" v-html="blogDetail.blog_desc"></p>

              <span v-if="blogDetail.blog_keywords">
                  <b-badge
                  v-for="(keywords, index) in blogDetail.blog_keywords.split(',')"
                  :key="index"
                  title="keywords"
                  class="text-wrap pointer mr-4 mb-2"
                  style="background-color: #e5252c !important;"
                  >{{ keywords }}</b-badge>
              </span>

              <!-- view attach document  -->
              <div class="col-md-12 m-0 p-0 mt-2">
                <!-- <h6>Download Attachments</h6> -->
                <template v-if="blogDetail.blog_attach && blogDetail.blog_attach.length">
                  <div v-for="(attachment, index) of parseToJSON(blogDetail.blog_attach)" :key="index">
                    <div class="attachmentCol mr-4">
                      <!-- <span class="pointer" @click="openFile(attachment.url)">{{ index+1 }}.&nbsp;&nbsp;{{ attachment.fileName ? attachment.fileName.split("/").pop() : attachment.url.split("/").pop() }}</span>&nbsp;&nbsp;&nbsp;&nbsp; -->
                      <img class="pointer" v-if="attachment.content" :src="pdfLogo" @click="openFile(attachment.url)" :title="attachment.fileName ? attachment.fileName.split('/').pop() : attachment.url.split('/').pop()">
                      <img v-else-if="attachment.fileName" :src="pdfLogo">
                      <img v-else alt="not found">
                    </div>
                  </div>
                </template>
              </div><!-- view attach document  -->
            </div>
          </div>
        </div>
      </div>
      <div :class="propOpenedInModal ? 'd-none' : 'col-lg-4'">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog-post">
          <div class="iq-card-header d-flex justify-content-between">
              <div class="header-title">
                  <h4 class="iq-card-title theme-color">
                      Other Blogs
                  </h4>
              </div>
          </div>
          <div class="iq-card-body right_side_bar">
              <ul class="row list-inline p-0 mb-0 ">
                  <li class=" mb-2 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12" v-for="(item, index) of blogObjList" :key="index">
                    <div class="row align-items-top pb-1 border-bottom pointer" @click="openUrlInNewTab(item.blog_id)">
                        <div class="col-sm-12 col-md-5 col-lg-5">
                          <div class="image-block" style="" v-if="item.blog_img">
                            <img :src="item.blog_img" class="img-fluid rounded w-100" style="" alt="blog-img">
                          </div>
                          <div v-else style="background-color: #EEEEEE; width: 100%; border-radius: 5px; display: flex; text-align: center;">
                            <span style="margin: auto; font-size: 36px;">{{ item.blog_title.slice(0, 1) }}</span>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-7 col-lg-7">
                          <div class="blog-description pl-2">
                            <h6 class="mb-0">{{ item.blog_title }}</h6>
                            <p class="mb-0 twoLineOnly other-desc" v-html="item.blog_desc"></p>
                          </div>
                        </div>
                    </div>
                  </li>
              </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Gide BLog Edit -->
    <b-modal
      v-model="showModelblogEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xxl"
    >
      <blogEdit :propOpenedInModal="true" :propblogObj="blogEditObj" @emitCloseblogEditModal="closeblogEditModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeblogEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- Gide BLog Edit -->

  </div>
</template>

<script>
import { blogs } from "../../FackApi/api/blog.js"
import { socialvue } from "../../config/pluginInit"
import pdfLogo from "../../assets_gide/img/logo/pdfLogo.png"
import moment from "moment"
import blogEdit from "../Gide/Blog/blogEdit.vue"

export default {
  name: "GideBlogView",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propBlogVueObj: {
      type: Object,
      default: null
    }
  },
  components: {
    blogEdit
  },
  data () {
    return {
      eventId: null,
      blogDetail: {},
      blogObjList: [],
      whereFilter: {},
      pdfLogo: pdfLogo,
      blogEditObj: null,
      showModelblogEdit: false,
      cvbtnModalCancel: "Close",
      cvEditModalHeader: "blogAdd"
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    this.blogId = this.$route.params.blogId
    await this.getBlogDetailView()
    this.blogList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * openUrlInNewTab
     */
    openUrlInNewTab (blogId) {
      window.open(`/blog_view/${blogId}`, "_blank")
    },

    /**
     * getBlogDetailView
    */
    async getBlogDetailView () {
      try {
        if (this.propBlogVueObj) {
          this.blogId = this.propBlogVueObj.blog_id
        }
        const blogtDetailResp = await blogs.blogView(this, this.blogId)
        if (blogtDetailResp.resp_status) {
          this.blogDetail = blogtDetailResp.resp_data.data
        }
      }
      catch (error) {
        console.error("Exception occurred at getBlogDetail() and Exception:", error.message)
      }
    },
    /**
     * blogList
     */
    async blogList () {
      try {
        // get the first 5 blogs only for displaying on the side panel
        this.whereFilter.get_top_blog = 10

        // Blogs related to same app in self-prep modules of testseries have app_id as blog_keywords so that they can be grouped togeather, hence pass the blog keywords only if its an app_id so that non self-prep blogs are not affected
        if (this.blogDetail.blog_keywords.includes("BLOGAPP")) {
          this.whereFilter.deleted = "03"
        }
        else {
          this.whereFilter.deleted = this.blogDetail.deleted
        }
        this.whereFilter.blog_keywords = this.blogDetail.blog_keywords

        let blogListResp = await blogs.blogList(this, this.whereFilter)
        if (blogListResp.resp_status) {
          this.blogObjList = blogListResp.resp_data.data
          this.showLoadMoreBtn = true
        }
        else {
          this.showLoadMoreBtn = false
          this.toastMsg = blogListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at blogList() and Exception:", err)
      }
    },
    /**
     * openFile
     */
    openFile (url) {
      if (url === "") {
        // empty url means that univ admin has not accepted the application
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "This document can only be viewed after accepting the application"
        return
      }

      window.open(url, "_blank")
    },
    /**
     * parseToJSON
     */
    parseToJSON (obj) {
      try {
        if (typeof obj === "string") {
          obj = JSON.parse(obj)
        }
        return obj
      }
      catch (jsonParseEx) {
        console.error("Exception in parseToJSON() and err: ", jsonParseEx)
        throw new Error("Invalid JSON, cammot parse")
      }
    },
    /**
     * blogEdit
     */
    blogEdit (item) {
      try {
        this.blogEditObj = item
        this.showModelblogEdit = true
      }
      catch (err) {
        console.error("Exception occurred at blogEdit() and Exception:", err.message)
      }
    },
    /**
     * closeblogEditModal
     */
    closeblogEditModal () {
      try {
        this.showModelblogEdit = false
        this.blogList()
      }
      catch (err) {
        console.error("Exception occurred at closeblogEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-device-width: 320px) and (max-device-width: 576px) {
    .image-block{
        width:100%;
        margin-bottom: 10px;
    }
}
.iq-card-body .right_side_bar{
    padding: 0px 15px;
}
@media screen and (min-width: 714px) {
    .register .border-bottom{
    line-height: 45px;
    padding-bottom: 10px !important;
  }
}
@media screen and (max-width:415px) {
  .register .border-bottom{
    height: 80px !important;
  }
  .blog-description h4{
    font-size: 28px;
  }
}
h4.iq-card-title{
  font-size:26px;
  font-weight: 600;
  color: #e5252c;
}
.twoLineOnly p {
  margin-bottom: 0px;
}
.blog-description h4{
  font-size: 38px;
  font-weight: 600;
}
.blog-description h6{
  font-size: 16px;
  font-weight: 500;
}
.blog_date{
  font-size: 20px;
  font-weight: 500;
}
.other-desc{
  font-size: 14px;
}
</style>
